'use client'

import { useState } from 'react'

function useSessionStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (typeof window !== 'undefined') {
        const item = window && window.sessionStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      } else {
        return initialValue
      }
    } catch (error) {
      console.error(`Error accessing local storage for key "${key}":`, error)
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      if (typeof window !== 'undefined') {
        setStoredValue(value)
        window.sessionStorage.setItem(key, JSON.stringify(value))
      }
    } catch (error) {
      console.error(`Error setting local storage for key "${key}":`, error)
    }
  }

  return [storedValue, setValue]
}

export default useSessionStorage
