'use client'

import { useEffect } from 'react'
import { useSearchParams } from 'next/navigation'

import useSessionStorage from './use-session-storage'

interface UTMValues {
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
  utm_content?: string
  utm_term?: string
  utm_id?: string
}

/**
 * Monitors query params and stores UTM related values into local storage for use on pardot forms
 */
export const useUtmTracker = () => {
  const [storedUtm, setStoredUtm] = useSessionStorage<UTMValues | null>(
    'utm_tracking',
    null
  )

  const searchParams = useSearchParams()

  useEffect(() => {
    const updatedUtm: UTMValues = {
      utm_campaign:
        searchParams?.get('utm_campaign') || storedUtm?.utm_campaign,
      utm_source: searchParams?.get('utm_source') || storedUtm?.utm_source,
      utm_medium: searchParams?.get('utm_medium') || storedUtm?.utm_medium,
      utm_content: searchParams?.get('utm_term') || storedUtm?.utm_content,
      utm_term: searchParams?.get('utm_term') || storedUtm?.utm_term,
      utm_id: searchParams?.get('utm_id') || storedUtm?.utm_id,
    }

    setStoredUtm(updatedUtm)
  }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const createQueryString = () => {
    if (!hasUtmValues()) {
      return ''
    }

    return `?utm_campaign=${storedUtm?.utm_campaign || ''}&utm_medium=${
      storedUtm?.utm_medium || ''
    }&utm_content=${storedUtm?.utm_content || ''}&utm_term=${
      storedUtm?.utm_term || ''
    }&utm_ID=${storedUtm?.utm_id || ''}&utm_source=${
      storedUtm?.utm_source || ''
    }`
  }

  const hasUtmValues = (): boolean => {
    if (
      storedUtm?.utm_campaign ||
      storedUtm?.utm_content ||
      storedUtm?.utm_id ||
      storedUtm?.utm_medium ||
      storedUtm?.utm_source ||
      storedUtm?.utm_term
    ) {
      return true
    }

    return false
  }

  return {
    storedUtm,
    createQueryString,
    hasUtmValues,
  }
}
