'use client'

import React, { useEffect } from 'react'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import AnalyticsProvider, {
  AnalyticsSettings,
} from '@cerifi/analytics/analytics-provider'
import ToastProvider from '@cerifi/ui/components/toast/toast-provider'
import { useUtmTracker } from '@cerifi/ui/hooks/use-utm-tracker'

interface ProvidersProps extends React.PropsWithChildren {
  draftMode: boolean
}

const analyticsSettings: AnalyticsSettings = {
  CONFIG: {
    GOOGLE_TAG_MANAGER: {
      KEY: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
    },
    GOOGLE_TAG_MANAGER_SECONDARY: {
      KEY: '',
    },
    META_PIXEL: {
      KEY: process.env.NEXT_PUBLIC_META_PIXEL_ID || '',
    },
  },
}

function Providers({ children, draftMode }: ProvidersProps) {
  const [client] = React.useState(
    new QueryClient({ defaultOptions: { queries: { staleTime: 5000 } } })
  )

  useUtmTracker()

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_DATADOG_ENABLED) return

    const init = async () => {
      const initDatadog = (await import('@cerifi/utils/datadog')).default
      initDatadog()
    }
    init()
  }, [])

  return (
    <QueryClientProvider client={client}>
      <ToastProvider />
      <ContentfulLivePreviewProvider
        locale="en-US"
        enableInspectorMode={draftMode || false}
        enableLiveUpdates={draftMode || false}
      >
        <AnalyticsProvider settings={analyticsSettings}>
          {children}
        </AnalyticsProvider>
      </ContentfulLivePreviewProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default Providers
